.App {
    overflow: hidden;
}

section {
    scroll-margin-top: 0;
}

.animate-bounce {
    animation: bounce 5s infinite;
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(-3%);
        animation-timing-function: cubic-bezier(0.2, 0, 1, 1);
    }
    50% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0, 0, 1, 1);
    }
}

@keyframes scroll {
    0% {
        /*Half of Gap*/
        left: 24px;
    }
    100% {
        left: -125%;
    }
}

.animate-scroll {
    animation: scroll 35s linear infinite;
}

.font-title {
    font-family: Helvetica, sans-serif;
}
